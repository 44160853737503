<template>
  <v-card>
    <v-card-text>
      <div class="subtitle-1 font-weight-bold">
        クーポン
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="v-align-middle"
              v-bind="attrs"
              v-on="on"
              color="#B8B8B8"
              @click="openFaq"
            >
              mdi-help-circle
            </v-icon>
          </template>
          <div>
            クーポンについて<br />
            詳しくはクリック
          </div>
        </v-tooltip>
      </div>
    </v-card-text>

    <v-card-text class="py-0 mx-auto text-center">
      <div class="py-auto block_item mt-3 px-2">
        <v-img
          width="130"
          src="https://ecupplus.blob.core.windows.net/plans/coupon50.png"
          class="block_item"
        ></v-img>
        <span class="text-h4 block_item">×</span>
        <span class="text-h4 grey-background py-auto px-3 block_item">
          {{ available_off_50_coupons_count }}
        </span>
      </div>

      <div class="py-auto block_item mt-3 px-2">
        <v-img
          width="130"
          src="https://ecupplus.blob.core.windows.net/plans/coupon10.png"
          class="block_item"
        ></v-img>
        <span class="text-h4 block_item">×</span>
        <span class="text-h4 grey-background my-0 px-3 block_item">
          {{ available_off_10_coupons_count }}
        </span>
      </div>
    </v-card-text>
    <v-card-text class="text-center py-0">
      <div
        class="grey-background rounded-xl mr-5 mt-5"
        style="width: 210px; display: inline-flex"
      >
        <div class="my-auto pa-3">クーポンの自動適用</div>
        <v-switch
          v-model="useCoupon"
          class="my-auto"
          style="width: 50px; display: inline-flex; margin-left: auto"
          hide-details
          :disabled="loadingUseCoupon"
          :loading="loadingUseCoupon"
          @change="switchUseCoupon()"
        ></v-switch>
      </div>
      <div
        class="mr-7"
        style="display: inline-flex; justify-content: space-evenly"
      >
        <a @click.stop.prevent="setCouponHistories()">獲得履歴</a>
      </div>
      <div
        style="display: inline-flex; justify-content: space-evenly"
        class="mt-5"
      >
        <v-btn color="primary pa-6" @click="showAddCoupon = true">
          <span>クーポン追加</span>
          <v-icon right>mdi-shape-square-rounded-plus</v-icon>
        </v-btn>
      </div>

      <div v-if="showCouponHistories">
        <modal>
          <v-card class="modal-container">
            <v-card-title class="justify-center"> クーポン履歴 </v-card-title>
            <v-card-text class="text-center">
              <v-simple-table height="300px">
                <tbody>
                  <tr v-for="history in couponHistories" :key="history.id">
                    <td>{{ time_ymd_format(history.created_at) }}</td>
                    <td class="text-left">
                      {{ history.shops_coupon.coupon.percent_off }}%OFFクーポン
                      {{ history.history_type | historyTypeJa }}
                      （合計{{ history.total }}枚）
                      <span
                        v-if="history.history_type === 'get'"
                        v-html="couponDetail(history)"
                      >
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="showCouponHistories = false">
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </modal>
      </div>

      <div v-if="showAddCoupon">
        <modal>
          <v-card class="modal-container">
            <v-card-title class="justify-center"> クーポン追加 </v-card-title>
            <v-card-text class="text-center">
              <v-row justify="center">
                <v-col cols="6">
                  <v-text-field
                    v-model="couponCode"
                    outlined
                    hide-details
                    class="my-auto"
                    label="クーポンコードを入力してください"
                    @keydown.enter="addCoupon()"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" class="align-self-center">
                  <v-btn
                    color="primary"
                    @click="addCoupon()"
                    :disabled="couponCode === '' || loadingAddCouponBtn"
                    :loading="loadingAddCouponBtn"
                    >追加</v-btn
                  >
                </v-col>
              </v-row>
              <p v-if="couponError" class="error--text text-body-1">
                {{ couponError }}
              </p>
              <p v-if="couponSuccess" class="success--text text-body-1">
                {{ couponSuccess }}
              </p>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="closeAddCoupon()"> 閉じる </v-btn>
            </v-card-actions>
          </v-card>
        </modal>
      </div>
    </v-card-text>

    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-text class="py-0">
      <div class="text-center grey-background py-5">
        <v-icon large block_item>mdi-account-arrow-right</v-icon>
        <span class="text-h6 block_item">紹介コード</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="v-align-middle"
              v-bind="attrs"
              v-on="on"
              color="#B8B8B8"
            >
              mdi-help-circle
            </v-icon>
          </template>
          <div>
            紹介コードをまだEC-UPに登録していないショップに教え、<br />
            新規登録時に入力し登録することで、紹介元と紹介先、それぞれクーポンを獲得することができます。
          </div>
        </v-tooltip>
        <span
          class="mx-auto block_item text-h4 ml-2"
          style="width: 280px; background-color: white"
          >{{ shop_code }}</span
        >
      </div>
    </v-card-text>

    <v-card-text class="text-right">
      <div class="pr-0 mr-0">
        <span class="mr-0">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                :href="facebook_share_url"
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#3B5998" large>mdi-facebook</v-icon>
              </a>
            </template>
            <div>紹介コードをFacebookでシェアします。</div>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                v-on="on"
                @click="
                  copy_referral_text();
                  referrel_code_coppied = true;
                "
                @mouseout="referrel_code_coppied = false"
              >
                <v-icon large>mdi-content-copy</v-icon>
              </a>
            </template>
            <div v-if="!referrel_code_coppied">
              紹介用テキストをコピーします。
            </div>
            <div v-else>コピーしました！</div>
          </v-tooltip>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import Modal from "../../components/Modal.vue";
import timeUtilsMixin from "../../../packs/mixins/time_utils";
import config from "../../../const";

export default {
  name: "CouponCard",
  props: [
    "available_off_50_coupons_count",
    "available_off_10_coupons_count",
    "use_coupon",
    "shop_code",
  ],

  data() {
    return {
      useCoupon: this.use_coupon,
      showCouponHistories: false,
      couponHistories: [],
      showAddCoupon: false,
      couponCode: "",
      couponError: null,
      couponSuccess: null,
      loadingAddCouponBtn: false,
      loadingUseCoupon: false,
      referrel_code_coppied: false,
    };
  },

  computed: {
    facebook_share_url() {
      return `https://www.facebook.com/sharer.php?quote=%E3%80%90%E7%B4%B9%E4%BB%8B%E3%82%B3%E3%83%BC%E3%83%89%EF%BC%9A${this.shop_code}%E3%80%91+%E6%9C%80%E7%9F%AD2%E3%82%AF%E3%83%AA%E3%83%83%E3%82%AF%E3%81%A7%E3%80%81%E6%A5%BD%E5%A4%A9%E5%B8%82%E5%A0%B4%E3%81%AE%E3%82%B9%E3%83%9E%E3%83%9B%E3%83%9A%E3%83%BC%E3%82%B8%E5%9B%9E%E9%81%8A%E5%AF%BE%E7%AD%96%E3%81%8C%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%80%8CEC-UP%E3%80%8D%E6%96%B0%E8%A6%8F%E7%94%B3%E8%BE%BC%E3%81%AE%E9%9A%9B%E3%80%81%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E5%85%A5%E5%8A%9B%E3%81%99%E3%82%8B%E3%81%A8%E3%80%811%E3%83%B6%E6%9C%88%E5%88%86%E3%81%AE%E6%96%99%E9%87%91%E3%81%8C%E5%8D%8A%E9%A1%8D%E3%81%AB%E3%81%AA%E3%82%8B%E3%82%AF%E3%83%BC%E3%83%9D%E3%83%B3%E3%82%92%E7%8D%B2%E5%BE%97%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%EF%BC%81&u=https%3A%2F%2Fec-up.app%2F#`;
    },

    referral_code_share_text() {
      return `【紹介コード】${this.shop_code}

最短2クリックで、楽天市場のスマホページ回遊対策ができるサービス「EC-UP」
新規申込の際、上記コードを入力するだけで、有料プラン1ヶ月分が半額でご利用いただける「50%OFFクーポン」を獲得できます！

▼EC-UPお申し込み
https://ec-up.app/#/sign_up

▼EC-UPとは
https://ec-up.jp/`;
    },
  },

  components: { Modal },

  filters: {
    historyTypeJa(type) {
      switch (type) {
        case "get":
          return "獲得";
        case "use":
          return "利用";
      }
    },
  },

  mixins: [timeUtilsMixin],

  methods: {
    async switchUseCoupon() {
      this.loadingUseCoupon = true;
      await axios
        .post("/api/v1/shops/me", {
          use_coupon: this.useCoupon,
        })
        .then((res) => {
          this.$emit("setUser", res.data);
        });
      this.loadingUseCoupon = false;
    },

    async setCouponHistories() {
      await axios.get("/api/v1/shops/coupons_histories").then((res) => {
        this.couponHistories = res.data;
      });

      this.showCouponHistories = true;
    },

    async addCoupon() {
      this.loadingAddCouponBtn = true;
      let res = await axios.post("/api/v1/shops/add_coupon", {
        coupon_code: this.couponCode,
      });
      if (res.data.success) {
        this.couponError = null;
        this.couponSuccess = res.data.message;
        this.couponCode = "";
        this.$emit("setUser", res.data.shop);
      } else {
        this.couponSuccess = null;
        this.couponError = res.data.message;
      }
      this.loadingAddCouponBtn = false;
    },

    closeAddCoupon() {
      this.showAddCoupon = false;
      this.couponCode = "";
      this.couponError = null;
      this.couponSuccess = null;
    },

    couponDetail(history) {
      switch (history.shops_coupon.coupon.code) {
        case "invites_10":
          return `<br />ご紹介ありがとうございます。${history.shops_coupon.invited_shop.name} さまが登録しました。`;
        case "invited_50":
          let shop_name =
            history.shops_coupon.invites_shop.name === "ECデータバンク"
              ? history.shops_coupon.invites_shop.name
              : history.shops_coupon.invites_shop.name + " さま";
          return `<br />ご登録ありがとうございます。${shop_name}の紹介により登録した事を確認し、通知しました。<br />有料プラン変更時に利用できる50％OFFクーポンを獲得しました。`;
        case "upgraded_50":
          return `<br />ご紹介ありがとうございます。${history.shops_coupon.invited_shop.name} さまが有料プランに変更しました。`;
        default:
          return `<br />${history.shops_coupon.coupon.name}`;
      }
    },

    openFaq() {
      window.open(config.HREF_FAQ + "/sections/5536491659151");
    },

    copy_referral_text() {
      navigator.clipboard.writeText(this.referral_code_share_text);
    },
  },
};
</script>

<style scoped>
.block_item {
  display: inline-block;
  vertical-align: middle;
}

.modal-container {
  max-width: 800px;
  padding: 30px;
}

.v-card__title {
  font-size: 2em;
  font-weight: 700;
}
</style>
