<template>
  <plan-change
    :user="user"
    :plan="plan"
    :selectedOptionCodes="selectedOptionCodes"
    :initialCost="initialCost"
    :googleFormUrl="googleFormUrl"
    @input="selectedOptionCodes = $event"
    v-if="plan"
  >
    <template #plan_confirmation>
      <paid-plan-confirmation
        :user="user"
        :plan="plan"
        :selectedOptionCodes="selectedOptionCodes"
        :initialCost="initialCost"
      >
      </paid-plan-confirmation>
    </template>
  </plan-change>
</template>

<script>
import userUtilsMixin from "../../packs/mixins/user_utils";
import PlanChange from "./components/PlanChange.vue";
import Axios from "axios";
import deepmerge from "deepmerge";
import PaidPlanConfirmation from "./components/plan_confirmation/PaidPlanConfirmation.vue";

export default {
  mixins: [userUtilsMixin],
  components: { PlanChange, PaidPlanConfirmation },
  computed: {
    user() {
      return this.$store.state.user;
    },

    initialCost() {
      if (!this.user) {
        return 0;
      }

      return !this.user.has_paid ? 50000 : 0;
    },

    googleFormUrl() {
      if (!this.user) {
        return null;
      }

      return `https://docs.google.com/forms/d/e/1FAIpQLScfb27GVnlSVQrxN4UKxqvyJPGMNj9rL68BCm-d69at5k6a2w/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
    },
  },

  watch: {
    plansMaster() {
      this.plan = deepmerge(
        this.plansMaster.find(
          (x) => x.code === this.additionalInformation.code
        ),
        this.additionalInformation
      );

      this.plan.plans_options.forEach((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          this.selectedOptionCodes.push(plans_option.option.code);
        }
      });

      // モーダルから来たとき
      if (this.$route.query.selectedOptionCodes) {
        let query = [this.$route.query.selectedOptionCodes].flat();
        this.selectedOptionCodes = this.selectedOptionCodes.filter(
          (selectedOptionCode) => {
            return query.includes(selectedOptionCode);
          }
        );
      }
    },
  },

  data() {
    return {
      plan: null,
      additionalInformation: {
        code: "platinum",
        color: "#4688A4",
        route_name: "ChangeToPlatinumPlan",
        icon: "https://ecupplus.blob.core.windows.net/plans/platinum.png",
        features: [
          "商品数2万以上の<br>メガショップさま向け",
          "50万商品まで料金変更なし",
          "更新頻度は商品数により変動",
        ],
      },
      plansMaster: {},
      selectedOptionCodes: [],
    };
  },

  methods: {},
  async created() {
    this.fetch_user();

    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });
  },
};
</script>
