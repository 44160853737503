<template>
  <v-container class="gold-trial-plan-features-card">
    <v-row justify="center">
      <v-col cols="auto">
        <v-img
          width="85px"
          :src="plan.icon"
          :style="{
            'margin-top': '-65px',
          }"
        />
      </v-col>
    </v-row>

    <v-row justify="center" class="black--text">
      <v-col cols="auto" class="py-0">
        おためし<br />
        1ヶ月限定
      </v-col>
      <v-col cols="auto" class="pt-0">
        <span class="text-h4">
          {{ TotalAmount.toLocaleString() }}
        </span>
        <span class="text-h6">円/月</span>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="TotalAmount">
      <v-col cols="auto" class="pa-0 text-body-2 secondary--text">
        1日あたり約{{ Math.floor(TotalAmount / 30).toLocaleString() }}円
      </v-col>
    </v-row>

    <v-row justify="center" style="min-height: 50px">
      <v-col cols="auto" class="pt-0">
        <v-checkbox
          v-for="plans_option in optionsToDisplay"
          :key="plans_option.option.code"
          v-model="modifiedSelectedOptionCodes"
          :label="`${
            plans_option.option.name
          } +${plans_option.amount.toLocaleString()}円`"
          :value="plans_option.option.code"
          dense
          :disabled="
            user.plan.code == plan.code ||
            plans_option.option.configuration_needed
          "
          hide-details
        >
        </v-checkbox>
      </v-col>
    </v-row>

    <slot name="btn" />

    <v-row justify="center" v-for="(plan_feature, i) in plan.features" :key="i">
      <v-col
        cols="auto"
        class="text-center text-body-2 pb-0"
        v-html="plan_feature"
      ></v-col>
    </v-row>

    <slot name="bottom_contents" />
  </v-container>
</template>

<script>
export default {
  props: ["user", "plan", "selectedOptionCodes"],
  computed: {
    modifiedSelectedOptionCodes: {
      get() {
        return this.selectedOptionCodes;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    optionTotalAmount() {
      let price = 0;

      this.optionsToDisplay.forEach((plans_option) => {
        if (
          this.modifiedSelectedOptionCodes.includes(plans_option.option.code)
        ) {
          price += plans_option.amount;
        }
      });

      return price;
    },

    TotalAmount() {
      return this.plan.amount + this.optionTotalAmount;
    },

    optionsToDisplay() {
      return this.plan.plans_options.filter((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          return true;
        }

        return false;
      });
    },
  },
};
</script>
