<template>
  <div class="signin">
    <v-container>
      <v-row justify="center">
        <v-col cols="sm-6 md-5" style="max-width: 500px">
          <v-card class="mx-auto px-3 py-6" id="user_registration_card">
            <v-card-text>
              <v-row justify="center">
                <v-col cols="auto">
                  <img
                    height="70px"
                    src="https://ecupplus.blob.core.windows.net/logo/ec-up_setLR_BIG_trans.gif"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="mailaddress"
                    type="email"
                    label="メールアドレス"
                    outlined
                    hide-details
                    id="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    :type="show_pw ? 'text' : 'password'"
                    label="パスワード"
                    outlined
                    hide-details
                    @keydown.enter="login"
                    :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_pw = !show_pw"
                    id="password"
                  ></v-text-field>
                </v-col>
                <p v-if="error" class="errors error--text">{{ error }}</p>
              </v-row>

              <v-row justify="center">
                <v-col cols="12">
                  <v-btn
                    @click="login"
                    id="sign_in_button"
                    color="primary"
                    class="title"
                    :loading="loading"
                    :disabled="loading"
                    block
                    x-large
                    >ログイン
                  </v-btn>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-icon left class="mr-0">mdi-form-textbox-password</v-icon
                ><router-link
                  :to="{ name: 'ForgotPassword' }"
                  class="text-decoration-underline"
                  >パスワードをお忘れの方</router-link
                >
              </v-row>
            </v-card-text>
          </v-card>

          <v-row justify="end" class="mt-3">
            <v-col cols="auto">
              <router-link
                :to="{ name: 'SignUp' }"
                class="text-decoration-underline"
              >
                新規登録
              </router-link>
            </v-col>
            <v-col cols="auto">
              <v-icon left class="mr-0">mdi-email</v-icon>
              <a
                :href="config('HREF_FEEDBACK')"
                target="_blank"
                class="text-decoration-underline ml-0"
              >
                お問い合わせ
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import config from "../../const";

export default {
  data: () => ({
    mailaddress: "",
    password: "",
    show_pw: false,
    error: "",
    loader: null,
    loading: false,
  }),

  methods: {
    login() {
      this.loading = true;

      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.mailaddress, this.password)
        .then(() => {
          const redirectPath = this.$route.query.redirect || "/"; // 元のパス、またはデフォルトでルートにリダイレクト
          this.$router.push(redirectPath);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = ((code) => {
            switch (code) {
              case "auth/user-not-found":
                return "メールアドレスが間違っています";
              case "auth/wrong-password":
                return "※パスワードが正しくありません";
              default:
                return "※メールアドレスとパスワードをご確認ください";
            }
          })(error.code);
        });
    },

    config(value) {
      return config[value];
    },
  },
};
</script>

<style scoped>
.signin {
  width: 100%;
}

#user_registration_card {
  margin-top: 50px;
  margin-bottom: 10px;
}

#sign_in_button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
