<template>
  <plan-change
    :user="user"
    :plan="plan"
    :selectedOptionCodes="selectedOptionCodes"
    :googleFormUrl="googleFormUrl"
    @input="selectedOptionCodes = $event"
    v-if="plan"
  >
    <template #plan_confirmation>
      <paid-plan-confirmation
        :user="user"
        :plan="plan"
        :percentOff="percentOff"
        :selectedOptionCodes="selectedOptionCodes"
      >
      </paid-plan-confirmation>
    </template>
  </plan-change>
</template>

<script>
import Axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import PlanChange from "./components/PlanChange.vue";
import PaidPlanConfirmation from "./components/plan_confirmation/PaidPlanConfirmation.vue";

export default {
  mixins: [userUtilsMixin],
  components: { PlanChange, PaidPlanConfirmation },
  computed: {
    user() {
      return this.$store.state.user;
    },

    percentOff() {
      if (!this.user) {
        return 0;
      }

      return 0 < this.user.my_available_off_50_coupons_count
        ? 50
        : 0 < this.user.my_available_off_10_coupons_count
          ? 10
          : 0;
    },

    googleFormUrl() {
      if (!this.user) {
        return null;
      }

      switch (this.user.plan.code) {
        case "trial":
        case "free":
          // 2
          return `https://docs.google.com/forms/d/e/1FAIpQLSfhXrcRNWezELutxNZPUl8RLu6N_KhI30j8tUlbuzjoRvP-Yg/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
        case "silver":
          // 5
          return `https://docs.google.com/forms/d/e/1FAIpQLSfNgib_AKJ8ogaVeSJPs5U5BylYXW_F2grdgBUz7E63DP91CQ/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
      }
    },
  },

  watch: {
    plansMaster() {
      this.plan = deepmerge(
        this.plansMaster.find(
          (x) => x.code === this.additionalInformation.code
        ),
        this.additionalInformation
      );

      this.plan.plans_options.forEach((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          this.selectedOptionCodes.push(plans_option.option.code);
        }
      });

      // モーダルから来たとき
      if (this.$route.query.selectedOptionCodes) {
        let query = [this.$route.query.selectedOptionCodes].flat();
        this.selectedOptionCodes = this.selectedOptionCodes.filter(
          (selectedOptionCode) => {
            return query.includes(selectedOptionCode);
          }
        );
      }
    },
  },

  data() {
    return {
      plan: null,
      additionalInformation: {
        code: "gold",
        color: "#CCA83F",
        icon: "https://ecupplus.blob.core.windows.net/plans/gold.png",
        features: [
          "月商1,000万以上の<br>大規模ショップさま向け",
          "2万商品まで対応",
          "1日2回以上更新<br><small>※商品数により変動</small>",
          "ラクイチアピール<br>デザイン16種類",
          "おまかせ機能<br>レイアウト変更可能",
        ],
      },
      plansMaster: {},
      selectedOptionCodes: [],
    };
  },

  async created() {
    this.fetch_user();

    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });
  },
};
</script>
