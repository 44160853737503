<template>
  <div class="signup">
    <v-container>
      <v-row justify="center">
        <v-col cols="10">
          <v-card class="mx-auto mb-0 pa-5" id="user_registration_card">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" style="display: inline-flex">
                    <span
                      class="text--primary text-h5 font-weight-bold mt-auto pb-1 pl-2"
                      style="
                        border-bottom: solid 4px #afd135; width: 174px
                        vertical-align: bottom;
                      "
                      >新規登録</span
                    >
                    <v-spacer></v-spacer>
                    <v-img
                      max-width="150"
                      src="https://ecupplus.blob.core.windows.net/logo/ec-up_setLR_BIG_trans.gif"
                    ></v-img>
                  </v-col>
                </v-row>

                <v-row justify="center" v-if="!once_shop_url_submit_succeed">
                  <v-col
                    cols="12"
                    class="text-center font-italic font-weight-bold mt-8"
                    style="font-size: 2rem"
                  >
                    あなたの楽天ショップの
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-center font-italic font-weight-bold mb-8"
                    style="font-size: 2rem"
                  >
                    店舗URLを入力してください
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols="12">
                    <div class="url-container">
                      <div class="rakuten-url">https://www.rakuten.co.jp/</div>
                      <v-text-field
                        v-model="shop_code"
                        label="店舗URL"
                        placeholder="例: oyasumi"
                        class="text--disabled"
                        id="shopurl_input_on_signup"
                        outlined
                        hide-details
                        style="flex: 1"
                      ></v-text-field>
                    </div>
                    <p
                      v-if="rakutenShopUrlError"
                      class="errors my-0 error--text text-body-1"
                    >
                      {{ rakutenShopUrlError }}
                    </p>
                  </v-col>

                  <v-col cols="12">
                    <v-btn
                      id="after_input_shop_url_button"
                      justify="center"
                      v-if="!once_shop_url_submit_succeed"
                      @click="rakutenShopUrlInput"
                      color="primary"
                      x-large
                      ><span class="font-weight-bold text-h6">次へ</span>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row
                  justify="center"
                  v-if="!once_shop_url_submit_succeed"
                  class="mt-7"
                >
                  <v-col cols="auto" class="text-subtitle-1">
                    <p class="font-weight-bold" style="color: #f09f4d">
                      ＼今なら新規ご登録で、最長30日間・有料プランの機能が無料でご利用いただけます／
                    </p>
                    <p>
                      ※トライアル開始時点では、決済手段のご登録は不要です。<br />
                      トライアル期間終了後に勝手に課金されることはありませんので、お気軽にご利用ください。
                    </p>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols="6" v-if="!rakutenShopUrlError" class="py-0">
                    <v-text-field
                      v-model="rakutenShopName"
                      v-if="rakutenShopName"
                      label="ショップ名"
                      hide-details
                      readonly
                      filled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" v-if="!rakutenShopUrlError" class="py-0">
                    <v-text-field
                      v-model="rakutenItemsCount"
                      v-if="rakutenItemsCount"
                      label="楽天商品数 ※倉庫商品は除く"
                      readonly
                      filled
                    ></v-text-field>
                    <div v-if="rakutenItemsCount">
                      <p
                        v-if="isExceedingItemLimit()"
                        class="error--text text-body-1"
                      >
                        商品数が50万を超えるショップさまで導入をご希望の場合は、下記よりお問い合わせください。
                      </p>
                      <p
                        v-if="isExceedingItemLimit()"
                        class="error--text text-body-1"
                      >
                        <a :href="config('HREF_FEEDBACK')"
                          >登録に関するお問い合わせ</a
                        >
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row justify="center" v-if="sellDrugs">
                  <v-col cols="auto">
                    <v-alert
                      border="left"
                      outlined
                      colored-border
                      color="#ac0000"
                    >
                      お取り扱い商品に関しまして、<span style="color: #ac0000"
                        >医薬品や医療機器に関するジャンルIDの商品を確認しました。</span
                      ><br />
                      EC-UPのご利用に支障はございませんが、特定の<span
                        style="color: #ac0000"
                        >対象ジャンルIDの商品につきましては、一部システムの制限を行っております。</span
                      >ご了承いただけますと幸いでございます。<br />
                      詳細については<a
                        href="https://ec-up.zendesk.com/hc/ja/articles/8152312088847"
                        target="_blank"
                        >こちら</a
                      >をご確認ください。
                    </v-alert>
                  </v-col>
                </v-row>

                <div v-if="withPlatinum">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <p class="text-center mb-0">
                        商品数が2万を超えるショップさまの場合、プラチナプランとなります。<br />
                        他プランとは異なる点が多くございますため、<br />
                        下記スライドをご一読くださいますようお願いいたします。
                      </p>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-carousel
                        show-arrows-on-hover
                        hide-delimiters
                        height="420"
                      >
                        <v-carousel-item
                          v-for="(item, i) in platinumOverview"
                          :key="i"
                          :src="item.src"
                        ></v-carousel-item>
                      </v-carousel>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-checkbox dense v-model="checkedPlatinumOverview">
                        <template v-slot:label>
                          <div class="font-weight-bold">
                            プラチナプラン概要を確認しました
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="12">
                      <v-card
                        color="grey lighten-3"
                        elevation="0"
                        class="text-center"
                      >
                        <v-card-text class="text-h6">
                          {{ rakutenShopName }}さま お見積
                        </v-card-text>
                        <v-divider class="mx-4"></v-divider>
                        <v-card-text class="text-h6">
                          初期費用：{{ initialCost | myriad }}万円（税込）<br />
                          利用料金：{{ usageFee | myriad }}万円（税込）<br />
                          <span class="text-caption">
                            ※商品数に関係なく一律{{
                              usageFee | myriad
                            }}万円となります。</span
                          ><br />
                          更新頻度：全商品の更新に約{{
                            updateFrequency
                          }}日<br />
                          <span class="text-caption"> ※目安となります。 </span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="isFurusatoShop">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <p class="text-h6 text-center font-weight-bold mb-0">
                        こちらはふるさと納税ショップ専用の新規登録画面です。<br />
                        スライドをご確認くださいませ。
                      </p>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-carousel
                        show-arrows-on-hover
                        hide-delimiters
                        height="420"
                      >
                        <v-carousel-item
                          v-for="(item, i) in furusatoOverview"
                          :key="i"
                          :src="item.src"
                        ></v-carousel-item>
                      </v-carousel>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="12">
                      <v-card
                        color="grey lighten-3"
                        elevation="0"
                        class="text-center px-12"
                      >
                        <v-card-text class="font-weight-bold">
                          <p class="text-h6 font-weight-bold mb-0">
                            個別相談会予約フォームは
                            <a
                              href="https://ec-up.jp/conference-form/"
                              class="text-decoration-underline"
                              target="_blank"
                              >コチラ</a
                            >
                          </p>
                          <p class="text-h6 font-weight-bold">
                            パートナーヒヤリングフォームは
                            <a
                              href="https://ec-up.jp/agency-form/"
                              class="text-decoration-underline"
                              target="_blank"
                              >コチラ</a
                            >
                          </p>
                          <p class="text-left mb-0">
                            ・お見積書サンプルダウンロードは
                            <a
                              href="https://ecdb.jp/dl/EC-UP_furusato_quotation.zip"
                              class="text-decoration-underline"
                              target="_blank"
                              >コチラ</a
                            >
                          </p>
                          <p class="text-left mb-0">
                            ・ご請求書サンプルダウンロードは
                            <a
                              href="https://ecdb.jp/dl/EC-UP_furusato_invoice.zip"
                              class="text-decoration-underline"
                              target="_blank"
                              >コチラ</a
                            >
                          </p>
                          <p class="text-left">
                            ・お支払先変更に関する書類サンプルダウンロードは
                            <a
                              href="https://ecdb.jp/dl/EC-UP_furusato_stripe.zip"
                              class="text-decoration-underline"
                              target="_blank"
                              >コチラ</a
                            >
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>

                <div
                  style="border: 5px solid #f2f2f2"
                  class="pa-10 mt-10"
                  v-if="shopRegisterable && !rakutenShopUrlError"
                >
                  <v-form ref="form" v-model="valid_email_password">
                    <v-row justify="center">
                      <v-col cols="12" class="pl-0">
                        <div
                          class="text-h6 text--primary font-weight-bold pl-3"
                          style="border-left: solid 4px #afd135"
                        >
                          アカウント登録
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="mailaddress"
                          label="メールアドレス"
                          outlined
                          validate-on-blur
                          hide-details
                          :rules="[rules.email]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="password"
                          label="パスワード"
                          :type="show_pw ? 'text' : 'password'"
                          outlined
                          :append-icon="show_pw ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show_pw = !show_pw"
                          :rules="[rules.password]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-row>
                    <v-col cols="12" class="pl-0">
                      <div
                        class="text-h6 text--primary font-weight-bold pl-3"
                        style="border-left: solid 4px #afd135"
                      >
                        CSV商品一括編集機能の利用確認
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div>
                        EC-UPは、楽天市場にて「CSV商品一括編集機能（有料）」をお申込みいただいているショップさまにご利用いただけるサービスです。<br />
                        ご利用中の場合はチェックしてください。ご利用されていない場合には、楽天市場RMSよりお申込みいただき、利用中となりましたらチェックしてください。
                      </div>
                      <div>
                        <a
                          class="text-decoration-underline"
                          @click="confirmAndMoveToRmsApplicationOfItemCsv()"
                          >楽天RMSにて利用情報を確認する</a
                        ><v-icon>mdi-window-restore</v-icon>
                      </div>
                      <div class="text-center text-h6 font-weight-black">
                        <v-checkbox
                          v-model="is_rakuten_bundle_edit_csv_enabled"
                          label="「CSV商品一括編集機能」を利用している"
                          class="shrink mr-0"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="pl-0">
                      <div
                        class="text-h6 text--primary font-weight-bold pl-3"
                        style="border-left: solid 4px #afd135"
                      >
                        紹介コード（お持ちの方）
                      </div>
                    </v-col>
                    <v-col
                      cols="8"
                      style="display: inline-flex; vertical-align: middle"
                    >
                      <v-text-field
                        v-model="invitation_code"
                        outlined
                        hide-details
                        class="my-auto"
                        label="紹介コード(お持ちの方)"
                      ></v-text-field>
                      <v-btn
                        class="my-auto ml-4"
                        color="primary"
                        @click="whoseInvitationCode()"
                        :disabled="invitation_code === '' || invitedBy !== ''"
                        >確認</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="py-0">
                      <p v-if="couponError" class="error--text text-body-1">
                        {{ couponError }}
                      </p>
                      <p v-if="invitedBy" class="success--text text-body-1">
                        {{ invitedBy | sentence }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="12" class="pb-0">
                      <div class="terms_of_service mb-0">
                        <TermsOfService />
                      </div>
                      <p class="text-right mb-0 subtitle-1">
                        <a
                          href="#"
                          @click.stop.prevent="dialog = true"
                          class="text-decoration-underline"
                          >全文表示</a
                        >
                      </p>
                    </v-col>
                    <v-col cols="auto" class="pa-0">
                      <div class="text-xs-center text-h6 font-weight-black">
                        <v-checkbox
                          v-model="terms_agreed"
                          label="規約に同意する"
                          style="font-size: 20px"
                          block
                          hide-details
                          dense
                          class="align-center justify-center"
                        ></v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <v-row justify="center" class="mt-3">
                  <v-col cols="12">
                    <v-btn
                      v-if="once_shop_url_submit_succeed"
                      id="sign_up_button"
                      @click="signUp"
                      color="primary"
                      :loading="loading"
                      :disabled="
                        !terms_agreed ||
                        (withPlatinum && !checkedPlatinumOverview) ||
                        loading ||
                        !valid_email_password
                      "
                      x-large
                      ><span class="font-weight-bold text-h6">登録する</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pt-0" v-if="loading">
                    <div class="mx-auto">
                      10秒程度かかります。リロードせずにお待ちください。
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-dialog v-model="dialog" absolute max-width="60%">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  <TermsOfService />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed @click="dialog = false"> 閉じる</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <p v-if="error" class="errors error--text">{{ error }}</p>
          </v-card>

          <v-row justify="end" class="mt-3">
            <v-col cols="auto">
              <router-link
                :to="{ name: 'SignIn' }"
                class="text-decoration-underline"
                v-if="isLoginButtonVisible"
              >
                ログイン
              </router-link>
            </v-col>

            <v-col cols="auto">
              <v-icon left class="mr-0">mdi-email</v-icon>
              <a
                :href="config('HREF_FEEDBACK')"
                target="_blank"
                class="text-decoration-underline ml-0"
              >
                お問い合わせ
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
axios.defaults.headers.common = {};
import TermsOfService from "../components/TermsOfService.vue";
import config from "../../const";
import EcUpFooter from "../components/EcUpFooter.vue";

export default {
  data: () => ({
    maxNumberOfItems: 500000,
    maxNumberOfItemsOfNormalRegistration: 20000,
    rakutenShopUrlError: null,
    isLoginButtonVisible: true,
    rakutenShopName: "",
    rakutenItemsCount: null,
    checkedPlatinumOverview: false,
    mailaddress: "",
    password: "",
    valid_email_password: false,
    show_pw: false,
    shop_code: null,
    is_rakuten_bundle_edit_csv_enabled: false,
    invitation_code: "",
    invitedBy: "",
    terms_agreed: false,
    error: "",
    loader: null,
    loading: false,
    dialog: false,
    couponError: null,
    once_shop_url_submit_succeed: false,
    initialCost: 50000,
    usageFee: 50000,
    NumberOfItemsUpdatedPerDay: 20000, // 一日あたりに更新可能な商品数
    isFurusatoShop: false,
    sellDrugs: false,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "メールアドレスが間違っています";
      },
      password: (value) => {
        const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
        return (
          pattern.test(value) ||
          "パスワードは次の条件を満たしてください。英小文字・英大文字・数字を含む８文字以上"
        );
      },
    },
    platinumOverview: [
      {
        src: "/images/sign_up/platinum/01.gif",
      },
      {
        src: "/images/sign_up/platinum/02.png",
      },
      {
        src: "/images/sign_up/platinum/03.png",
      },
      {
        src: "/images/sign_up/platinum/04.png",
      },
      {
        src: "/images/sign_up/platinum/05.png",
      },
    ],
    furusatoOverview: [
      {
        src: "/images/sign_up/furusato/01.gif",
      },
      {
        src: "/images/sign_up/furusato/02.png",
      },
      {
        src: "/images/sign_up/furusato/03.png",
      },
      {
        src: "/images/sign_up/furusato/04.png",
      },
      {
        src: "/images/sign_up/furusato/05.png",
      },
    ],
  }),

  components: {
    TermsOfService,
    EcUpFooter,
  },

  computed: {
    withPlatinum() {
      return (
        this.isExceedingItemLimitOfNormalRegistration() &&
        !this.isExceedingItemLimit()
      );
    },

    shopRegisterable() {
      if (!this.rakutenItemsCount) {
        return false;
      }
      if (this.isExceedingItemLimit()) {
        return false;
      }
      if (this.rakutenShopUrlError) {
        return false;
      }
      return true;
    },
    filteredShopCode() {
      return this.shop_code
        .replace("https://www.rakuten.ne.jp/gold/", "")
        .replace("https://www.rakuten.co.jp/", "")
        .replace(/^\/*gold\//, "")
        .replace("/", "")
        .replace(/\s/g, "");
    },

    updateFrequency() {
      return (
        Math.floor(this.rakutenItemsCount / this.NumberOfItemsUpdatedPerDay) + 1
      );
    },
  },
  filters: {
    myriad(value) {
      return Math.floor(value / 10000);
    },

    sentence(value) {
      return value === "ECデータバンク"
        ? value + "からの紹介です。"
        : value + " さまの紹介コードです。";
    },
  },
  created() {
    // URLからshop_codeパラメータを取得し、データプロパティにセット
    if (this.$route.params.shop_code) {
      this.invitation_code = this.$route.params.shop_code;
    }
  },
  methods: {
    isExceedingItemLimit() {
      return this.maxNumberOfItems < this.rakutenItemsCount;
    },

    isExceedingItemLimitOfNormalRegistration() {
      return this.maxNumberOfItemsOfNormalRegistration < this.rakutenItemsCount;
    },

    confirmAndMoveToRmsApplicationOfItemCsv: function () {
      if (confirm("RMSの「CSV商品一括編集機能申込」に遷移します。")) {
        window.open(
          "https://master.rms.rakuten.co.jp/rms/mall/rsf/shop/vc?__event=RS71_000_000",
          "_blank"
        );
      }
    },
    signUp() {
      this.loading = true;

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.mailaddress, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          const token = user.getIdToken(true);
          const shop = {
            email: user.email,
            name: this.rakutenShopName,
            uid: user.uid,
            shop_code: this.filteredShopCode,
            mall: "rakuten",
            is_rakuten_bundle_edit_csv_enabled:
              this.is_rakuten_bundle_edit_csv_enabled,
            agreed_to_terms: this.terms_agreed,
            items: this.rakutenItemsCount,
          };
          axios
            .post(`/api/v1/auth/registrations`, {
              shop: shop,
              token: token,
              invitation_code: this.invitation_code,
            })
            .then(() => {
              this.$router.push({ name: "InstallationStatus" });
            });
        })
        .catch((error) => {
          console.log(error);
          this.error = ((code) => {
            switch (code) {
              case "auth/email-already-in-use":
                return "既にそのメールアドレスは使われています";
              case "auth/wrong-password":
                return "※パスワードが正しくありません";
              case "auth/weak-password":
                return "※パスワードは最低6文字以上にしてください";
              default:
                return "※メールアドレスとパスワードをご確認ください";
            }
          })(error.code);
          this.loading = false;
        });
    },
    async rakutenShopUrlInput() {
      let shop_registered = await axios.post(`/api/v1/shops/registered`, {
        shop_code: this.filteredShopCode,
      });
      if (!shop_registered.data.permitted) {
        this.rakutenShopUrlError =
          "EC-UPにご登録いただけません。申し訳ございません。";
        return;
      } else if (shop_registered.data.registered) {
        this.rakutenShopUrlError = "EC-UPに登録済みのショップです。";
        return;
      }

      if (this.filteredShopCode) {
        let itemsCountUrl = `https://app.rakuten.co.jp/services/api/IchibaItem/Search/20170706?format=json&shopCode=${this.filteredShopCode}&applicationId=1080177728851498177&hits=1&formatVersion=2&availability=0`;
        this.rakutenShopUrlError = null;
        // axios のデフォルトヘッダ設定により
        // アフィAPIへのリクエストがcorsに引っかかるのでここではfetchを使用
        let res = await fetch(itemsCountUrl);
        if (res.status == 200) {
          let responseBody = await res.json();
          if (0 < responseBody.Items.length) {
            this.rakutenItemsCount = responseBody.count;
            this.rakutenShopName = responseBody.Items[0].shopName;
            this.once_shop_url_submit_succeed = true;
            // ふるさと納税ショップか
            // 'f' + 数字6桁 + '-' + 1文字以上の文字列
            this.isFurusatoShop = /^f\d{6}-.+/.test(this.filteredShopCode);
            // ログインボタンを非表示にする
            this.isLoginButtonVisible = false;
            // 遷移したらフッターを非表示にするフラグをセット
            this.$store.commit("setFooterLinks", false);
            // 医薬品チェック
            let res = await axios.post("/api/v1/shops/sell_drugs", {
              shop_code: this.filteredShopCode,
            });
            this.sellDrugs = res.data.sell_drugs;
          } else {
            this.rakutenShopUrlError =
              "指定されたショップurlの商品が見つかりませんでした。";
          }
        } else if (res.status == 400) {
          this.rakutenShopUrlError =
            "ショップurlが間違っている可能性があります。再度ご確認ください。";
        } else {
          this.rakutenShopUrlError =
            "楽天のシステム障害が発生している可能性があります。後ほど再度お試しください。";
        }
      } else {
        this.rakutenShopUrlError = "urlが不正です";
      }
    },

    async whoseInvitationCode() {
      await axios
        .post("/api/v1/shops/whose_invitation_code", {
          invitation_code: this.invitation_code,
        })
        .then((res) => {
          if (res.data.shop_name !== "") {
            this.invitedBy = res.data.shop_name;
            this.couponError = "";
          } else {
            this.couponError =
              "クーポンコードが正しく入力されているかご確認ください。";
          }
        });
    },

    config(value) {
      return config[value];
    },
  },
};
</script>

<style scoped>
.signup {
  width: 100%;
}

.url-container {
  display: flex;
  align-items: center;
}

.rakuten-url {
  font-size: 1.5rem;
  margin-right: 8px;
  color: #00000061;
}

#after_input_shop_url_button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#user_registration_card {
  margin-top: 50px;
  margin-bottom: 50px;
}

#sign_up_button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#ecai_csv_bundle_edit_on_rakuten_span {
  color: black;
}

.terms_of_service {
  height: 15em;
  margin-bottom: 1em;
  padding: 1em;
  border: 1px solid lightgray;
  overflow-y: scroll;
}

.v-input--checkbox .v-label {
  width: 100px;
}
</style>
