<template>
  <div class="form-container">
    <form v-if="!successMessage" @submit.prevent="handleSubmit">
      <div class="input-group">
        <label for="order-number">注文番号入力</label>
        <input 
          type="text" 
          v-model="orderNumber" 
          id="order-number" 
          @input="formatOrderNumber" 
          maxlength="26" 
          required 
        />
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </div>
      <button type="submit">送信する</button>
    </form>
    <div v-if="successMessage" class="success-message">
      <p class="success">{{ successMessage }}</p>
      <button @click="closeWindow">閉じる</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['shopCode', 'accessToken'],
  data() {
    return {
      orderNumber: '',
      errorMessage: '',
      successMessage: '' // 送信成功メッセージ用のデータ
    };
  },
  methods: {
    handleSubmit() {
      const formattedOrderNumber = this.orderNumber.toString();
      console.log('Order number submitted:', formattedOrderNumber);
      this.sendOrderNumberToServer(formattedOrderNumber);
    },
    formatOrderNumber() {
      let value = this.orderNumber.replace(/-/g, ''); // ハイフンを一旦削除
      const regex = /^[0-9]*$/; // 半角数字のみ許可

      if (!regex.test(value)) {
        this.errorMessage = '半角数字のみを入力してください。';
        this.orderNumber = value.replace(/[^0-9]/g, ''); // 半角数字以外を削除
        return;
      } else {
        this.errorMessage = '';
      }

      // 最大桁数を21に制限（ハイフンを含めない）
      if (value.length > 24) {
        value = value.slice(0, 24);
      }

      if (value.length > 6) value = value.slice(0, 6) + '-' + value.slice(6);
      if (value.length > 15) value = value.slice(0, 15) + '-' + value.slice(15);

      this.orderNumber = value;
    },
    sendOrderNumberToServer(orderNumber) {
      fetch('/liff_endpoint/callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          orderNumber: orderNumber,
          shop_code: this.shopCode,
          accessToken: this.accessToken
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to send order number');
        }
        return response.json();
      })
      .then(data => {
        console.log('Order number sent successfully', data);
        this.successMessage = '送信に成功しました'; // 送信成功時のメッセージを設定
        this.errorMessage = ''; // エラーメッセージをクリア
      })
      .catch(err => {
        console.error('Error sending order number', err);
        this.errorMessage = '送信に失敗しました。再試行してください。'; // エラーメッセージを設定
        this.successMessage = ''; // 成功メッセージをクリア
      });
    },
    closeWindow() {
      window.close();
    }
  },
};
</script>

<style scoped>
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 1em;
}

label {
  font-size: 1em;
  margin-bottom: 0.5em;
  display: block;
}

input {
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 0.7em;
  font-size: 1em;
  color: white;
  background-color: #00B900; /* LINE で使っている緑と同じ設定にした */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #009900;
}

.success-message {
  margin-top: 1em;
  text-align: center;
}

.success-message button {
  background-color: #00B900;
  padding: 0.5em 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 1em;
}

.success-message button:hover {
  background-color: #009900;
}

.error {
  color: red;
  margin-top: 5px;
}

.success {
  color: green;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .form-container {
    padding: 0.5em;
  }

  label, input, button {
    font-size: 0.9em;
  }
}
</style>
