import { render, staticRenderFns } from "./LiffLoginForm.vue?vue&type=template&id=f2d03540&scoped=true&"
import script from "./LiffLoginForm.vue?vue&type=script&lang=js&"
export * from "./LiffLoginForm.vue?vue&type=script&lang=js&"
import style0 from "./LiffLoginForm.vue?vue&type=style&index=0&id=f2d03540&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d03540",
  null
  
)

export default component.exports