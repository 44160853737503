import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "../packs/firebase";
import VueAnalytics from "vue-analytics";

import Root from "../view/Root.vue";
import appVue from "../app.vue";
import DashboardPage from "../view/dashboard/Index.vue";
import InstructionsPage from "../view/dashboard/Instructions.vue";
import InstallationStatusPage from "../view/installation_status/Index.vue";
import ItemForSmartPhonePage from "../view/items/ForSmartPhone.vue";
import ItemForPCPage from "../view/items/ForPC.vue";
import ItemBySalesMethodPage from "../view/items/BySalesMethod.vue";
import ItemProcedureOneItemPage from "../view/items/procedure/OneItem.vue";
import ItemProcedureMultipleItemsPage from "../view/items/procedure/MultipleItems.vue";
import InvitationPage from "../view/invitation_page/InvitationPage.vue";
import SignUpPage from "../view/auth/SignUp.vue";
import SignInPage from "../view/auth/SignIn.vue";
import ForgotPasswordPage from "../view/auth/ForgotPassword.vue";
import EmailChangePage from "../view/auth/EmailChange.vue";
import PasswordChangePage from "../view/auth/PasswordChange.vue";
import WithdrawPage from "../view/auth/Withdraw.vue";
import WithdrawCompletePage from "../view/auth/WithdrawComplete.vue";
import ChangeToPlatinumPlanPage from "../view/plan/ChangeToPlatinumPlan.vue";
import ChangeToGoldPlanPage from "../view/plan/ChangeToGoldPlan.vue";
import ChangeToSilverPlanPage from "../view/plan/ChangeToSilverPlan.vue";
import ChangeToFreePlanPage from "../view/plan/ChangeToFreePlan.vue";
import ChangeToGoldTrialPlanPage from "../view/plan/ChangeToGoldTrialPlan.vue";
import ConfigFullAutoPage from "../view/config/FullAuto.vue";
import ConfigDisplaySettingsPage from "../view/config/DisplaySettings.vue";
import ShopAdmin from "../view/shop_admin/index.vue";
import ShopPageLayouts from "../view/shop_page/Layouts.vue";
import ShopPageDisplaySettings from "../view/shop_page/DisplaySettings.vue";
import HowToShopTop from "../view/shop_page/HowTo.vue";
import Maintenance from "../view/Maintenance.vue";
import Impersonation from "../view/Impersonation.vue";
import store from "../store/index";

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        });
      });
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      component: Root,
      name: "Root",
    },
    {
      path: "/dashboard",
      component: DashboardPage,
      name: "Dashboard",
    },
    {
      path: "/dashboard/instructions",
      component: InstructionsPage,
      name: "Instructions",
      meta: { isSubWindow: true },
    },
    {
      path: "/installation_status",
      component: InstallationStatusPage,
      name: "InstallationStatus",
    },
    {
      path: "/items/for_smartphone",
      component: ItemForSmartPhonePage,
      name: "ItemForSmartPhone",
    },
    {
      path: "/items/for_pc",
      component: ItemForPCPage,
      name: "ItemForPC",
    },
    {
      path: "/items/by_sales_method",
      component: ItemBySalesMethodPage,
      name: "ItemBySalesMethod",
    },
    {
      path: "/items/procedure/one_item",
      component: ItemProcedureOneItemPage,
      name: "ItemProcedureOneItem",
      meta: { isSubWindow: true },
    },
    {
      path: "/items/procedure/multiple_items",
      component: ItemProcedureMultipleItemsPage,
      name: "ItemProcedureMultipleItems",
      meta: { isSubWindow: true },
    },
    {
      path: "/sign_up/:shop_code?",
      component: SignUpPage,
      name: "SignUp",
      meta: { isPublic: true },
      beforeEnter: async (to, from, next) => {
        const user = await firebase.getCurrentUser();
        const shopCode = to.params.shop_code;
        if (user) {
          next({ name: "Root" });
        } else {
          next();
        }
      },
    },
    {
      path: "/sign_in",
      component: SignInPage,
      name: "SignIn",
      meta: { isPublic: true },
      beforeEnter: async (to, from, next) => {
        const user = await firebase.getCurrentUser();
        if (user) {
          next({ name: "Root" });
        } else {
          next();
        }
      },
    },
    {
      path: "/forgot_password",
      component: ForgotPasswordPage,
      name: "ForgotPassword",
      meta: { isPublic: true },
    },
    {
      path: "/password_change",
      component: PasswordChangePage,
      name: "PasswordChange",
    },
    {
      path: "/email_change",
      component: EmailChangePage,
      name: "EmailChange",
    },
    {
      path: "/withdraw",
      component: WithdrawPage,
      name: "Withdraw",
      meta: { isWithdraw: true },
    },
    {
      path: "/withdraw_complete",
      component: WithdrawCompletePage,
      name: "WithdrawComplete",
      meta: {
        isChangePlan: true,
        isPublic: true,
      },
      props: true,
    },
    {
      path: "/plan/change_to_platinum",
      component: ChangeToPlatinumPlanPage,
      name: "ChangeToPlatinumPlan",
      meta: { isChangePlan: true },
    },
    {
      path: "/plan/change_to_gold",
      component: ChangeToGoldPlanPage,
      name: "ChangeToGoldPlan",
      meta: { isChangePlan: true },
    },
    {
      path: "/plan/change_to_silver",
      component: ChangeToSilverPlanPage,
      name: "ChangeToSilverPlan",
      meta: { isChangePlan: true },
    },
    {
      path: "/plan/change_to_free",
      component: ChangeToFreePlanPage,
      name: "ChangeToFreePlan",
      meta: { isChangePlan: true },
    },
    {
      path: "/plan/change_to_gold_trial",
      component: ChangeToGoldTrialPlanPage,
      name: "ChangeToGoldTrialPlan",
      meta: { isChangePlan: true },
    },
    {
      path: "/config/full_auto",
      component: ConfigFullAutoPage,
      name: "ConfigFullAuto",
    },
    {
      path: "/config/display_settings",
      component: ConfigDisplaySettingsPage,
      name: "ConfigDisplaySettings",
    },
    {
      path: "/shops/admin",
      component: ShopAdmin,
      name: "ShopAdmin",
    },
    {
      path: "/shop_page_layouts",
      component: ShopPageLayouts,
      name: "ShopPageLayouts",
    },
    {
      path: "/shop_page_layouts/display_settings",
      component: ShopPageDisplaySettings,
      name: "ShopPageDisplaySettings",
    },
    {
      path: "/shop_page_layouts/how_to",
      component: HowToShopTop,
      name: "HowToShopTop",
      meta: { isSubWindow: true },
    },
    {
      path: "/maintenance",
      component: Maintenance,
      name: "Maintenance",
      meta: { isPublic: true },
    },
    {
      path: "/impersonation",
      component: Impersonation,
      name: "Impersonation",
    },
    {
      path: "/invitation_page/:shop_code",
      component: InvitationPage,
      name: "InvitationPage",
      meta: {
        isPublic: true,
        isIsolatedStyle: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const userDetails = store.state.userDetails; // Vuex ストアから userDetails を取得

  if (userDetails) {
    // userDetails が存在する場合、executeCodeForOnboardingTool メソッドを呼び出す
    appVue.methods.executeCodeForOnboardingTool(userDetails);
  }

  if (!store.state.setFooterLinks) {
    store.commit("setFooterLinks", true); // フッターを表示に変更
  }

  const user = await firebase.getCurrentUser();

  if (to.matched.some((record) => record.meta.isPublic)) {
    next();
  } else {
    if (user) {
      next();
    } else {
      next({ name: "SignIn", query: { redirect: to.fullPath } });
    }
  }
});

Vue.use(VueAnalytics, {
  id: "UA-39574740-6",
  router,
});

export default router;
