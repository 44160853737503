<template>
  <v-row justify="center">
    <v-col
      cols="11"
      :class="{
        active_step_body: mode == 'active' || mode == 'fetching_items',
        not_yet_step_body: mode == 'not_yet',
        'pa-5': true,
      }"
    >
      <v-row justify="center">
        <v-col
          cols="auto"
          :class="{
            'text-h6': true,
            'text-center': true,
            'font-weight-bold': true,
            ecup_green_text: true,
          }"
          v-if="mode == 'active' || mode == 'fetching_items'"
          color="success"
        >
          <v-icon style="vertical-align: middle" color="success" left>
            mdi-party-popper
          </v-icon>
          <span style="vertical-align: middle">
            初期設定が完了しました！<br />
            利用開始メールを待ちましょう！
          </span>
        </v-col>
        <v-col
          :class="{
            'text-h6': true,
            'text-center': true,
            'font-weight-bold': true,
          }"
          v-else-if="mode == 'not_yet'"
          color="#c3c3c3"
          cols="auto"
        >
          <v-icon style="vertical-align: middle" color="#c3c3c3"
            >mdi-alert-circle</v-icon
          >
          <span style="vertical-align: middle">EC-UP未設定</span>
        </v-col>
      </v-row>

      <v-divider class="my-3"></v-divider>

      <v-row v-if="mode == 'fetching_items' || mode == 'not_yet'">
        <v-col class="text-center text-body-1 mx-auto" cols="auto">
          <v-switch
            dense
            inset
            @change="updateUserConfig($event)"
            v-model="is_full_auto_on"
          >
            <template v-slot:label>
              <span class="font-weight-bold"> 「おまかせ機能を利用する」 </span>
              <span>
                <help-for-full-auto>
                  <template v-slot:link>
                    <v-icon class="v-align-middle" color="#B8B8B8"
                      >mdi-help-circle</v-icon
                    >
                  </template>
                  <template v-slot:note>
                    <v-card-text>
                      ※あとから設定を変更することも可能です。<br />
                      商品別におまかせ機能をON・OFFにすることもできます。
                    </v-card-text>
                  </template>
                </help-for-full-auto>
              </span>
            </template>
          </v-switch>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto"> 初期設定のお手続き、ありがとうございました。</v-col>
      </v-row>
      <v-row v-if="mode == 'fetching_items'" justify="center">
        <v-col cols="auto" class="text-center">
          現在利用準備を行っております。完了しましたらメール（【EC-UP】ご利用開始のお知らせ）をお送りします。<br />
          メールが届いたら、最下部「EC-UPを利用する」ボタンを押して、利用を開始しましょう！<br />
          商品数により、ご案内までに時間がかかる場合がございます。（目安：1000商品で1～2時間程度）<br />
          <br />
          その間、簡単なアンケートにご協力お願いいたします。
        </v-col>
      </v-row>
      <v-row v-else-if="mode == 'active'" justify="center">
        <v-col cols="auto"> 引き続き、EC-UPをご利用ください。 </v-col>
      </v-row>

      <v-row>
        <!-- アンケート -->
        <v-col v-if="mode == 'fetching_items'">
          <iframe
            :src="google_form_url"
            width="100%"
            height="1000"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            >読み込んでいます…</iframe
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col style="margin: 0 200px" class="my-2">
          <v-btn
            block
            x-large
            color="success"
            @click="$router.push({ name: 'Dashboard' })"
            :disabled="mode != 'active'"
          >
            <span class="white--text">EC-UPを利用する </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";
import HelpForFullAuto from "../../components/HelpForFullAuto.vue";

export default {
  name: "step-card",
  mixins: [],
  components: { HelpForFullAuto },
  props: {
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        // プロパティの値は、必ずいずれかの文字列でなければならない
        return ["active", "not_yet", "fetching_items"].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    google_form_url() {
      return `https://docs.google.com/forms/d/e/1FAIpQLSeSWqOubI9j7uF9WnXmm90tZYVjXjYiwKS_jlLvIgzRgzBJhA/viewform?embedded=true&entry.451931980=${this.user.shop_code}`;
    },
  },
  watch: {
    user: async function (newVal, oldVal) {
      if (newVal) {
        this.is_full_auto_on = newVal.is_full_auto_on;
      }
    },
  },
  data() {
    return {
      is_full_auto_on: true,
    };
  },
  methods: {
    async updateUserConfig(event) {
      let res = await axios.post("/api/v1/shops/me", {
        is_full_auto_on: event,
        is_full_auto_on_BySalesMethod: event,
        is_full_auto_on_ForPC: event,
      });
      this.SET_USER(res.data);
    },
    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>

<style scoped>
.active_step_body {
  border: solid 2px #92b126;
}
.not_yet_step_body {
  border: solid 2px #eeeeee;
}
.finished_step_triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #eeeeee;
}
.active_step_triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #92b126;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eeeeee;
}
</style>
