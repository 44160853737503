<template>
  <div>
    <v-row justify="center" class="mt-6">
      <v-col cols="6">
        <v-row>
          <v-col>
            <black-rounded-subtitle>デザインを選択する</black-rounded-subtitle>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              v-model="selectDisplayFormat"
              class="mt-0 pt-0"
              mandatory
              hide-details
            >
              <div class="d-flex">
                <label class="d-flex">
                  <v-radio id="landscape" value="landscape" />
                  <div>
                    <v-img src="images/horizontal.bmp" max-width="56" height="56" alt="横スクロール" />
                    <div class="mt-2">横スクロール</div>
                  </div>
                </label>
                <label class="d-flex ml-6">
                  <v-radio id="portrait" value="portrait" />
                  <div>
                    <v-img src="images/vertical.bmp" max-width="56" height="56" alt="縦配置" />
                    <div class="mt-2">縦配置</div>
                  </div>
                </label>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col>
            <black-rounded-subtitle>表示商品数</black-rounded-subtitle>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="selectSize"
              :items="sizeItems"
              outlined
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col>
        <black-rounded-subtitle>表示箇所を選択する</black-rounded-subtitle>
      </v-col>
    </v-row>
    <div ref="scrollArea" class="my-6 mx-auto pa-4" style="width:460px;height:440px;overflow-y:auto;border:3px solid #eee;">
      <v-row class="pa-2">
        <v-col
          v-for="(part, index) in parts"
          :key="part.id"
          cols="12"
          ma="2"
        >
          <v-sheet
            class="d-flex"
            :height="part.shop_top_system_id === shopTopSystem.id ? parts.length > 1 ? 232 : 220 : 32"
          >
            <v-row v-if="part.shop_top_system_id !== shopTopSystem.id" class="ma-0 pt-1 pl-2 grey lighten-3">
              {{ part.name }}
            </v-row>
            <v-row v-else class="ma-0" style="border:6px solid #2a8cc7;background-color:#fff;">
              <div class="pt-1 pl-2">
                商品パーツ　｜　{{ part.name }}
              </div>
              <div
                v-if="selectDisplayFormat !== 'portrait'"
                class="d-flex flex-grow-1"
                style="overflow-x:scroll;width:400px;"
              >
                <div v-for="i in selectSize" :key="'count-' + i">
                  <v-sheet class="ma-2 pt-1 pl-2" color="#cacaca" height="150" width="150">
                    {{ i }}
                  </v-sheet>
                </div>
              </div>
              <div
                v-else
                class="d-flex flex-wrap justify-center mx-auto mb-2"
                style="overflow-y:scroll;width:400px;max-height:158px;"
              >
                <div v-for="i in selectSize" :key="'count-' + i">
                  <v-sheet class="ma-2 pl-2" color="#cacaca" height="150" width="150">
                    {{ i }}
                  </v-sheet>
                </div>
              </div>

              <div
                v-if="parts.length > 1"
                class="mt-auto ml-auto"
                style="margin: 0px -6px 0px 0px;"
              >
                <v-btn color="primary" x-small tile fab @click="sort(index, 0)">
                  <v-icon>mdi-chevron-double-up</v-icon>
                </v-btn>
                <v-btn color="primary" x-small tile fab @click="sort(index, index - 1)">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn color="primary" x-small tile fab @click="sort(index, index + 1)">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
                <v-btn color="primary" x-small tile fab @click="sort(index, parts.length - 1)">
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </div>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <v-row class="pa-6">
      <v-col>
        <div class="d-flex">
          <v-btn x-large class="flex-grow-0 grey white--text font-weight-bold" @click="$emit('close')">戻る</v-btn>
          <v-btn x-large color="primary" class="flex-grow-1 ml-4 text-h6 font-weight-bold" @click="update">更新する</v-btn>
        </div>
        <div v-if="isUpdate" class="mt-4">
          <v-btn x-large block color="#e53935" class="white--text text-h6 font-weight-bold" @click="remove">取り外す</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import BlackRoundedSubtitle from "./BlackRoundedSubtitle.vue";

export default {
  name: "design-selection",
  mixins: [],
  components: {
    BlackRoundedSubtitle
  },
  props: {
    shopPageLayout: { type: Object },
    shopTopSystem: { type: Object },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isUpdate() {
      return this.shopPageLayout['widget_count_' + this.shopTopSystem.code] > 0;
    }
  },
  watch: {},
  async mounted() {
    const params = {
      shop_page_layout_id: this.shopPageLayout.id
    };

    const response = await axios.get("/api/v1/shop_page_layouts/get_parts", { params });
    this.parts = response.data.parts.sort((a,b) => a.order - b.order);

    const target = response.data.parts.find((part) => part.shop_top_system_id === this.shopTopSystem.id);
    if (target) {
      this.selectDisplayFormat = target.display_format;
      this.selectSize = target.size;
    } else {
      this.parts.push({
        id: null,
        order: this.parts.length,
        name: this.shopTopSystem.name,
        shop_top_system_id: this.shopTopSystem.id
      });
    }

    this.$nextTick(() => {
      this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight;
    });
  },
  data() {
    return {
      selectDisplayFormat: 'horizontal',
      selectSize: 8,
      sizeItems: [
        { text: '2', value: 2 },
        { text: '4', value: 4 },
        { text: '8', value: 8 },
        { text: '10', value: 10 },
        { text: '12（最大）', value: 12 },
      ],
      parts: []
    };
  },
  methods: {
    sort(fromIndex, toIndex) {
      if (toIndex < 0) return;
      if (toIndex >= this.parts.length) return;
      if (fromIndex === toIndex) return;

      const cloneParts = [...this.parts];

      if (toIndex === 0) {
        const target = cloneParts.splice(fromIndex, 1);
        cloneParts.unshift(target[0]);
      } else if (toIndex === this.parts.length - 1) {
        const target = cloneParts.splice(fromIndex, 1);
        cloneParts.push(target[0]);
      } else {
        [cloneParts[toIndex], cloneParts[fromIndex]] = [this.parts[fromIndex], this.parts[toIndex]];
      }

      this.parts = cloneParts;
    },
    update() {
      const index = this.parts.findIndex((part) => part.shop_top_system_id === this.shopTopSystem.id);
      const target = this.parts[index];

      const params = {
        shop_page_part_id: target.id,
        display_format: this.selectDisplayFormat,
        size: this.selectSize,
        order: index + 1,
      };

      this.$emit('update', params);
    },
    remove() {
      const target = this.parts.find((part) => part.shop_top_system_id === this.shopTopSystem.id);
      this.$emit('remove', target.id);
    }
  },
};
</script>

<style scoped>
</style>
