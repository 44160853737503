<template>
  <transition name="modal" v-if="isRender">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container px-7 py-3">
          <v-container>
            <v-row id="ikkatsu_modal_head_row">
              <v-col cols="6" align-self="end" class="pb-0">
                <div class="text-h5">システム設定</div>
              </v-col>
              <v-col
                cols="1"
                style="padding-bottom: 0px"
                align-self="end"
                class="pa-0 offset-sm-5"
              >
                <v-icon @click="$emit('close')" class="modal_close_icon"
                  >mdi-close-box</v-icon
                >
              </v-col>
            </v-row>

            <operation-selection-status
              :shop-page-layout="shopPageLayout"
              :shop-top-system="shopTopSystem"
            />

            <div class="modal-body mt-3">
              <design-selection
                :shop-page-layout="shopPageLayout"
                :shop-top-system="shopTopSystem"
                @close="$emit('close')"
                @update="updateShopPageLayout"
                @remove="removeShopTopSystem"
              />
            </div>
          </v-container>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import OperationSelectionStatus from "./SystemEditModal/OperationSelectionStatus.vue";
import DesignSelection from "./SystemEditModal/DesignSelection.vue";

import ToolUtilsMixin from "../../../packs/mixins/tool_utils";

export default {
  name: "system-edit-modal",
  mixins: [ToolUtilsMixin],
  props: {
    isRender: {
      type: Boolean,
      default: false
    },
    shopPageLayout: { type: Object },
    shopTopSystem: { type: Object },
  },
  components: {
    OperationSelectionStatus,
    DesignSelection
  },
  watch: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
    };
  },
  methods: {
    updateShopPageLayout(params) {
      params.operation = params.shop_page_part_id ? 'update_part' : 'create_part';

      this.creatingJobRequest(params).then(() => {
        this.$emit('save');
      });
    },
    removeShopTopSystem(partId) {
      const params = {
        operation: 'delete_part',
        shop_page_part_id: partId
      };

      this.creatingJobRequest(params).then(() => {
        this.$emit('save');
      });
    },
    async creatingJobRequest(params) {
      params.shop_page_layout_id = this.shopPageLayout.id;
      params.shop_top_system_id = this.shopTopSystem.id;
      params.status = 'wating';

      let job = await axios.post("/api/v1/shop_top_jobs", params);
      return job.data;
    },
  },
};
</script>

<style scoped>
#ikkatsu_modal_head_row {
  border-bottom: 3px solid #cccccc;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 730px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
  height: auto;
  max-height: 88vh;
  vertical-align: middle;
}

.modal_close_icon {
  display: block;
  margin: 0 0 0 auto;
  font-size: 3rem;
}
</style>
