<template>
  <v-container fluid class="pa-6 text-center">
    <v-row justify="center">
      <v-col cols="auto">
        <v-img max-width="250" src="https://ecupplus.blob.core.windows.net/logo/ec-up_setLR_BIG_trans.gif"></v-img>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <p class="text-h6">
          ただいまメンテナンスを行っております。<br />
          メンテナンス終了までお待ちください。
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <p>メンテナンス期間</p>
        <p>
          {{ time_ymdt_format(starts_at) }}<br />
          ～<br />
          {{ time_ymdt_format(ends_at) }}（予定）
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <p>
          ※上記期間中はEC-UPの定期更新は行われません。定期更新はメンテナンス終了後に行われます。
        </p>
        <p>
          ※メンテナンス期間は予定なく変更・短縮・延長される場合がございます。
        </p>
        <p>
          大変申し訳ございませんが、何卒ご協力のほどよろしくお願いいたします。
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import timeUtilsMixin from "../packs/mixins/time_utils";

export default {
  name: "Maintenance",

  mixins: [timeUtilsMixin],

  data() {
    return {
      starts_at: null,
      ends_at: null,
    };
  },

  created() {
    this.getDisplayMaintenancePeriod();
  },

  methods: {
    // 画面上に表示するメンテナンス期間の取得
    async getDisplayMaintenancePeriod() {
      try {
        const response = await axios.get("/api/v1/maintenance/setting_period");
        const data = response.data;

        // メンテナンス期間をセット
        this.starts_at = new Date(data.starts_at);
        this.ends_at = new Date(data.ends_at);

        const currentTime = new Date();

        if (currentTime < this.starts_at || currentTime > this.ends_at) {
          // メンテナンス期間外の場合、トップページにリダイレクト
          this.$router.push({ name: "Root" });
        }
      } catch (error) {
        console.error("メンテナンス期間の取得に失敗しました", error);
      }
    },
  },
};
</script>
