<template>
  <plan-change
    :user="user"
    :plan="plan"
    :selectedOptionCodes="selectedOptionCodes"
    :googleFormUrl="googleFormUrl"
    @input="selectedOptionCodes = $event"
    v-if="plan"
  >
    <template #plan_confirmation>
      <paid-plan-confirmation
        :user="user"
        :plan="plan"
        :nextPlan="goldPlan"
        :selectedOptionCodes="selectedOptionCodes"
      >
      </paid-plan-confirmation>
    </template>
  </plan-change>
</template>

<script>
import Axios from "axios";
import deepmerge from "deepmerge";
import userUtilsMixin from "../../packs/mixins/user_utils";
import PlanChange from "./components/PlanChange.vue";
import PaidPlanConfirmation from "./components/plan_confirmation/PaidPlanConfirmation.vue";

export default {
  mixins: [userUtilsMixin],
  components: { PlanChange, PaidPlanConfirmation },
  computed: {
    user() {
      return this.$store.state.user;
    },

    googleFormUrl() {
      if (!this.user) {
        return null;
      }

      return `https://docs.google.com/forms/d/e/1FAIpQLSeQrjeE-Z0cxPsoabJ_s-U3ou795M0uvEaa5NOKD1s6LHtbaw/viewform?usp=pp_url&entry.2060341977=${this.user.shop_code}`;
    },

    goldPlan() {
      return deepmerge(
        {
          code: "gold",
          color: "#FFD700",
          route_name: "ChangeToGoldPlan",
          icon: "https://ecupplus.blob.core.windows.net/plans/gold.png",
          features: [
            "月商1,000万以上の<br>大規模ショップさま向け",
            "2万商品まで対応",
            "1日2回以上更新<br><small>※商品数により変動</small>",
            "ラクイチアピール<br>デザイン16種類",
            "おまかせ機能<br>レイアウト変更可能",
          ],
        },
        this.plansMaster.find((x) => x.code === "gold")
      );
    },

    goldTrialAvailable() {
      return (
        this.user &&
        (this.user["gold_trial_available?"] ||
          this.user.plan.code === "gold_trial")
      );
    },
  },

  watch: {
    plansMaster() {
      this.plan = deepmerge(
        this.plansMaster.find(
          (x) => x.code === this.additionalInformation.code
        ),
        this.additionalInformation
      );

      this.plan.plans_options.forEach((plans_option) => {
        if (plans_option.is_available && plans_option.is_showing) {
          this.selectedOptionCodes.push(plans_option.option.code);
        }
      });

      // モーダルから来たとき
      if (this.$route.query.selectedOptionCodes) {
        let query = [this.$route.query.selectedOptionCodes].flat();
        this.selectedOptionCodes = this.selectedOptionCodes.filter(
          (selectedOptionCode) => {
            return query.includes(selectedOptionCode);
          }
        );
      }
    },
  },

  data() {
    return {
      plan: null,
      additionalInformation: {
        code: "gold_trial",
        color: "#CCA83F",
        icon: "https://ecupplus.blob.core.windows.net/plans/gold.png",
        features: [
          "月商1,000万以上の<br>大規模ショップさま向け",
          "2万商品まで対応",
          "1日2回以上更新<br><small>※商品数により変動</small>",
          "ラクイチアピール<br>デザイン16種類",
          "おまかせ機能<br>レイアウト変更可能",
        ],
      },
      plansMaster: {},
      selectedOptionCodes: [],
    };
  },

  methods: {},
  async created() {
    this.fetch_user();

    if (!this.goldTrialAvailable) {
      this.$router.push({ name: "Root" });
    }

    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });
  },
};
</script>
