<template>
  <ecup-modal
    :title="
      user.plan.code == 'trial'
        ? 'プラン変更：トライアル期間中はゴールドプランと同等の内容をご利用いただいておりました'
        : 'プラン変更'
    "
    @close_this_modal="$emit('close_this_modal')"
    modal_width="680px"
  >
    <v-container>
      <v-container v-if="0 < plans.length">
        <v-row justify="center" class="mb-10">
          <v-col cols="6" v-for="(plan, i) in plans" :key="i">
            <div class="balloon-bottom" v-if="plan.appeal">
              <v-icon color="white" large> mdi-crown </v-icon>
              {{ plan.appeal }}
            </div>
          </v-col>
          <v-col cols="6" v-if="goldTrialAvailable">
            <div class="balloon-bottom" v-if="goldTrialPlan.appeal">
              <v-icon color="white" large> mdi-crown </v-icon>
              {{ goldTrialPlan.appeal }}
            </div>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col
            cols="6"
            class="plan_card_in_modal py-5"
            v-for="(plan, i) in plans"
            :key="i"
          >
            <plan-features-card
              :user="user"
              :plan="plan"
              :percentOff="percentOff"
              :selectedOptionCodes="selectedOptionCodes"
              @input="selectedOptionCodes = $event"
            >
              <template #btn>
                <v-row>
                  <v-col>
                    <v-btn
                      large
                      block
                      disabled
                      text
                      height="60px"
                      v-if="user.plan.code === plan.code"
                    >
                      <v-icon left> mdi-check </v-icon>
                      現在のプランです
                    </v-btn>
                    <v-btn
                      large
                      block
                      disabled
                      height="60px"
                      v-else-if="exceeded_item_limit_in(plan.max_num_of_items)"
                    >
                      商品数超過のため<br />ご利用いただけません
                    </v-btn>
                    <v-btn
                      large
                      block
                      color="primary"
                      height="60px"
                      @click="
                        $router.push({
                          name: plan.route_name,
                          query: { selectedOptionCodes: selectedOptionCodes },
                        })
                      "
                      v-else
                    >
                      {{ plan.name }}プランに<br />変更する
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
              </template>
            </plan-features-card>
          </v-col>

          <v-col
            cols="6"
            class="gold-trial-plan-card-in-modal px-0"
            v-if="goldTrialAvailable"
          >
            <gold-trial-plan-features-card
              :user="user"
              :plan="goldTrialPlan"
              :selectedOptionCodes="selectedOptionCodes"
              @input="selectedOptionCodes = $event"
            >
              <template #btn>
                <v-row>
                  <v-col>
                    <v-btn
                      large
                      block
                      disabled
                      text
                      height="60px"
                      v-if="user.plan.code === goldTrialPlan.code"
                    >
                      <v-icon left> mdi-check </v-icon>
                      現在のプランです
                    </v-btn>
                    <v-btn
                      large
                      block
                      disabled
                      height="60px"
                      v-else-if="
                        exceeded_item_limit_in(goldTrialPlan.max_num_of_items)
                      "
                    >
                      商品数超過のため<br />ご利用いただけません
                    </v-btn>
                    <v-btn
                      large
                      block
                      color="primary"
                      height="60px"
                      @click="
                        $router.push({
                          name: goldTrialPlan.route_name,
                          query: { selectedOptionCodes: selectedOptionCodes },
                        })
                      "
                      v-else
                    >
                      {{ goldTrialPlan.name }}プランに<br />変更する
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
              </template>
            </gold-trial-plan-features-card>
          </v-col>
        </v-row>
      </v-container>

      <v-row>
        <v-col cols="12" class="px-0 pb-2">
          <v-btn
            block
            text
            color="success"
            @click="$router.push({ name: 'ChangeToFreePlan' })"
            v-if="!isPlatinum"
          >
            フリープランに変更する
          </v-btn>
        </v-col>
        <v-col cols="12" class="px-0 pb-2">
          <v-btn
            large
            block
            color="white"
            @click="priceList = true"
            :elevation="3"
          >
            プラン詳細はこちら
          </v-btn>
        </v-col>
        <v-col cols="12" class="px-0 pt-2">
          <a :href="config('HREF_FAQ')" target="_blank">
            <v-btn
              large
              block
              color="white"
              @click="$emit('close_this_modal')"
              elevation="3"
            >
              クーポンや各システムについてのよくある質問はこちら
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </a>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="priceList" width="auto">
      <v-card>
        <v-card-actions>
          <v-divider></v-divider>
          <v-btn icon fab text x-large @click="priceList = false">
            <v-icon> mdi-close-box </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-img src="/images/plans/price_list.png"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </ecup-modal>
</template>

<script>
import EcupModal from "../components/EcupModal.vue";
import PlanFeaturesCard from "./components/PlanFeaturesCard.vue";
import GoldTrialPlanFeaturesCard from "./components/GoldTrialPlanFeaturesCard.vue";
import Axios from "axios";
import deepmerge from "deepmerge";
import config from "../../const";

export default {
  name: "template",

  mixins: [],

  components: { EcupModal, PlanFeaturesCard, GoldTrialPlanFeaturesCard },

  computed: {
    user() {
      return this.$store.state.user;
    },

    isPlatinum() {
      return this.max_num_of_items_of_free < this.user.items_count;
    },

    goldTrialAvailable() {
      return (
        this.user &&
        (this.user["gold_trial_available?"] ||
          this.user.plan.code === "gold_trial")
      );
    },

    percentOff() {
      return this.isPlatinum
        ? 0
        : 0 < this.user.my_available_off_50_coupons_count
        ? 50
        : 0 < this.user.my_available_off_10_coupons_count
        ? 10
        : 0;
    },

    plansToDisplay() {
      if (this.isPlatinum) {
        return [
          {
            code: "platinum",
            color: "#4688A4",
            route_name: "ChangeToPlatinumPlan",
            icon: "https://ecupplus.blob.core.windows.net/plans/platinum.png",
            features: [
              "商品数2万以上の<br>メガショップさま向け",
              "50万商品まで料金変更なし",
              "更新頻度は商品数により変動",
            ],
          },
        ];
      } else {
        let plans = [
          {
            code: "silver",
            color: "#FFD700",
            route_name: "ChangeToSilverPlan",
            icon: "https://ecupplus.blob.core.windows.net/plans/silver.png",
            features: [
              "月商1,000万までの<br>小・中規模ショップさま向け",
              "1万商品まで対応",
              "1日1回更新",
              "ラクイチアピール<br>デザイン4種類",
            ],
          },
        ];

        if (this.goldTrialAvailable) {
        } else {
          plans.push({
            code: "gold",
            color: "#FFD700",
            route_name: "ChangeToGoldPlan",
            icon: "https://ecupplus.blob.core.windows.net/plans/gold.png",
            features: [
              "月商1,000万以上の<br>大規模ショップさま向け",
              "2万商品まで対応",
              "1日2回以上更新<br><small>※商品数により変動</small>",
              "ラクイチアピール<br>デザイン16種類",
              "おまかせ機能<br>レイアウト変更可能",
            ],
            appeal: "おすすめプラン",
          });
        }

        return plans;
      }
    },

    goldTrialPlan() {
      return deepmerge(
        {
          code: "gold_trial",
          color: "#FFD700",
          route_name: "ChangeToGoldTrialPlan",
          icon: "https://ecupplus.blob.core.windows.net/plans/gold.png",
          features: [
            "月商1,000万以上の<br>大規模ショップさま向け",
            "2万商品まで対応",
            "1日2回以上更新<br><small>※商品数により変動</small>",
            "ラクイチアピール<br>デザイン16種類",
            "おまかせ機能<br>レイアウト変更可能",
          ],
          appeal: "おすすめプラン",
        },
        this.plansMaster.find((x) => x.code === "gold_trial")
      );
    },
  },

  watch: {
    plansMaster() {
      this.plans = this.plansToDisplay.map((planToDisplay) => {
        return deepmerge(
          planToDisplay,
          this.plansMaster.find((x) => x.code === planToDisplay.code)
        );
      });
    },
  },

  data() {
    return {
      plans: {},
      plansMaster: {},
      priceList: false,
      max_num_of_items_of_free: 20000,
      selectedOptionCodes: [],
    };
  },

  created() {
    Axios.get("/api/v1/plans/").then((res) => {
      this.plansMaster = res.data.plans;
    });

    Axios.get("/api/v1/options/").then((res) => {
      res.data.options.forEach((option) => {
        let shopsOption = this.user.shops_options.find(
          (x) => x.option.code === option.code && x.is_enabled
        );

        if (shopsOption) {
          this.selectedOptionCodes.push(option.code);
        }
      });
    });
  },

  methods: {
    exceeded_item_limit_in(max_num_of_items) {
      return max_num_of_items < this.user.items_count;
    },

    config(value) {
      return config[value];
    },
  },
};
</script>

<style scoped>
.plan_card_in_modal {
  border: solid 2px #cccccc;
  margin: -1px;
}

.coupon_string_in_plan_feature {
  color: red;
}

.balloon-bottom {
  position: relative;
  padding: 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: #cca83f;
  margin-bottom: 1em;
  border-radius: 7px;
  top: 5px;
}

/* afterで三角を表現 */
.balloon-bottom::after {
  content: "";
  position: absolute;
  left: 105px;
  bottom: -15px;
  display: block;
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-top: 30px solid #cca83f;
  border-left: 30px solid transparent;
}

.gold-trial-plan-card-in-modal {
  border: solid 10px #ffd700;
  margin: -1px;
}
</style>
