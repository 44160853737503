<template>
  <v-card class="pa-8 mt-5">
    <v-card-title class="pa-0 mb-3">
      <v-row>
        <v-col cols="auto"> おまかせ機能 ON/OFF </v-col>
        <v-col cols="auto">
          <help-for-full-auto>
            <template v-slot:link>
              <v-icon small>mdi-help-circle</v-icon>
              <span class="v-align-middle text-body-2"> おまかせ機能とは </span>
            </template>
          </help-for-full-auto>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-subtitle class="px-0 mt-0">
      【ON推奨】おまかせ機能を利用するかどうかを設定します。
    </v-card-subtitle>

    <v-card-text class="pt-4 pb-7 px-0 mx-auto" style="max-width: 980px">
      <v-row>
        <v-col cols="6">
          <v-row align="center" class="py-1">
            <v-col cols="auto">
              <v-icon large class="success--text"> mdi-cellphone-text </v-icon>
            </v-col>
            <v-col cols="auto" class="pl-0">
              <span class="grey--text text--darken-4 body-1 font-weight-medium"
                >スマホ用商品説明文</span
              >
            </v-col>
            <v-col cols="auto">
              <v-switch
                v-model="is_full_auto_on"
                inset
                class="pt-0 mt-0"
                hide-details
                :label="is_full_auto_on ? 'ON' : 'OFF'"
                @click="updateFullAuto('is_full_auto_on')"
                :loading="loading"
                :disabled="user.plan.code == 'free' || loading"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row v-if="user.plan.code == 'free'">
            <v-col cols="auto" class="text-caption"
              >※フリープランではご利用いただけません。</v-col
            >
          </v-row>
        </v-col>

        <v-col cols="6">
          <v-row align="center" class="py-1">
            <v-col cols="auto">
              <v-icon
                large
                class="success--text"
                style="transform: rotateX(180deg)"
              >
                mdi-comment-text-outline
              </v-icon>
            </v-col>
            <v-col cols="auto" class="pl-0">
              <span class="grey--text text--darken-4 body-1 font-weight-medium"
                >PC用販売説明文</span
              >
            </v-col>
            <v-col cols="auto">
              <v-switch
                v-model="is_full_auto_on_BySalesMethod"
                inset
                class="pt-0 mt-0"
                hide-details
                :label="is_full_auto_on_BySalesMethod ? 'ON' : 'OFF'"
                @click="updateFullAuto('is_full_auto_on_BySalesMethod')"
                :loading="loading"
                :disabled="!user['use_for_pc?'] || loading"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row align="center" class="py-1">
            <v-col cols="auto">
              <v-icon
                large
                class="success--text"
                style="transform: rotateY(180deg)"
              >
                mdi-comment-text-outline
              </v-icon>
            </v-col>
            <v-col cols="auto" class="pl-0">
              <span class="grey--text text--darken-4 body-1 font-weight-medium"
                >PC用商品説明文</span
              >
            </v-col>
            <v-col cols="auto">
              <v-switch
                v-model="is_full_auto_on_ForPC"
                inset
                class="pt-0 mt-0"
                hide-details
                :label="is_full_auto_on_ForPC ? 'ON' : 'OFF'"
                @click="updateFullAuto('is_full_auto_on_ForPC')"
                :loading="loading"
                :disabled="!user['use_for_pc?'] || loading"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row v-if="!user['use_for_pc?']">
            <v-col cols="auto" class="text-caption"
              >※ご利用にはオプション機能「PCページ自動更新」の追加が必要です。</v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <DesignLayout
      v-if="
        is_full_auto_on ||
        is_full_auto_on_BySalesMethod ||
        is_full_auto_on_ForPC
      "
      :user="user"
      :loading="loading"
      @updated="$emit('updated', $event)"
    />

    <RakuichiOn
      v-if="
        is_full_auto_on ||
        is_full_auto_on_BySalesMethod ||
        is_full_auto_on_ForPC
      "
      :user="user"
      :loading="loading"
      @updated="$emit('updated', $event)"
    />

    <ReviewOn
      v-if="
        is_full_auto_on ||
        is_full_auto_on_BySalesMethod ||
        is_full_auto_on_ForPC
      "
      :user="user"
      :config="reviewConfig"
      :loading="loading"
      @update="updateReviewConfig"
    />

    <FavoriteOn
      v-if="
        is_full_auto_on ||
        is_full_auto_on_BySalesMethod ||
        is_full_auto_on_ForPC
      "
      :user="user"
      :config="favoriteConfig"
      :loading="loading"
      @update="updateFavoriteConfig"
    />
  </v-card>
</template>

<script>
import HelpForFullAuto from "../../../components/HelpForFullAuto.vue";
import RakuichiOn from "./rakuichi/RakuichiOn.vue";
import ReviewOn from "./review/ReviewOn.vue";
import FavoriteOn from "./favorite/FavoriteOn.vue";
import DesignLayout from "./design/DesignLayout.vue";

export default {
  props: ["user", "loading"],

  data() {
    return {
      is_full_auto_on: null,
      is_full_auto_on_BySalesMethod: null,
      is_full_auto_on_ForPC: null,
      config: null,
      reviewConfig: null,
      favoriteConfig: null,
    };
  },

  components: {
    HelpForFullAuto,
    RakuichiOn,
    ReviewOn,
    FavoriteOn,
    DesignLayout,
  },

  watch: {
    user: {
      handler(val) {
        this.is_full_auto_on = val.is_full_auto_on;
        this.is_full_auto_on_BySalesMethod = val.is_full_auto_on_BySalesMethod;
        this.is_full_auto_on_ForPC = val.is_full_auto_on_ForPC;
        this.config = val.config;
        this.reviewConfig = this.config?.tools?.review;
        this.favoriteConfig = this.config?.tools?.favorite;
      },
      immediate: true,
    },
  },

  methods: {
    async updateFullAuto(param) {
      const data = {};
      data[param] = this[param];
      this.$emit("update-full-auto", data);
    },

    updateReviewConfig(val) {
      this.$emit("update-config", { tools: { review: val } });
    },

    updateFavoriteConfig(val) {
      this.$emit("update-config", { tools: { favorite: val } });
    },
  },
};
</script>
