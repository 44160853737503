<template>
  <modal>
    <v-card class="modal-container">
      <v-card-title class="justify-center">
        <v-img
          src="https://ecupplus.blob.core.windows.net/logo/ec-up_setLR_BIG_trans.gif"
          max-height="100"
          max-width="150"
        ></v-img>
      </v-card-title>

      <v-card-text class="text-center grey--text text--darken-3">
        <v-row justify="center">
          <v-col cols="auto">
            <p class="text-subtitle-1 font-weight-bold">
              システムを稼働させましょう！
            </p>
            <p class="text-caption">
              ※おまかせ機能ONのショップさまは、AIによる自動運用となりますので、<br />
              商品ごとの設定は一切不要です。
            </p>
            <p class="text-caption">
              ▼スライド画像で、EC-UPの使い方をご説明しております
            </p>
            <v-carousel show-arrows-on-hover hide-delimiters height="320">
              <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-btn large block color="primary" @click="$emit('close')">
              閉じる
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </modal>
</template>

<script>
import Modal from "../../components/Modal.vue";

export default {
  components: { Modal },
  data() {
    return {
      items: [
        {
          src: "/images/items/procedure/01.gif",
        },
        {
          src: "/images/items/procedure/02.png",
        },
        {
          src: "/images/items/procedure/03.png",
        },
        {
          src: "/images/items/procedure/04.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
.modal-container {
  max-width: 600px;
}
</style>
