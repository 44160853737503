<template>
  <v-card width="400">
    <v-card-text>
      <div style="display: flex">
        <div>おまかせ機能：</div>
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch
                  color="primary"
                  class="mt-0 pt-0"
                  value
                  input-value="true"
                  dense
                  v-model="is_full_auto_on_toggle"
                  @change="changeFullAuto($event)"
                  hide-details
                  :loading="loadingToggle"
                ></v-switch>
              </div>
            </template>
            <span>{{ full_auto_on_off_str }}</span>
          </v-tooltip>
        </div>
        <div class="ml-auto mr-0">
          <help-for-full-auto>
            <template v-slot:link>
              <v-icon small>mdi-help-circle</v-icon>
              <span class="v-align-middle">機能についての詳細はこちら</span>
            </template>
            <template v-slot:img v-if="imgUrl">
              <v-card-text class="headline font-weight-bold">
                <v-row>
                  <v-col> ▼{{ name }} </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-img :src="imgUrl"></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    ※ゴールドプラン/プラチナプランは、おまかせ機能のカスタマイズが可能です。お問い合わせください。
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </help-for-full-auto>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import { SET_USER } from "store/mutation-types";
import HelpForFullAuto from "../../components/HelpForFullAuto.vue";

export default {
  props: ["is_full_auto_on", "name", "imgUrl"],
  components: { HelpForFullAuto },
  data() {
    return {
      loadingToggle: false,
      is_full_auto_on_toggle: this.is_full_auto_on,
    };
  },
  name: "full-auto-config-card",
  computed: {
    full_auto_on_off_str() {
      if (this.is_full_auto_on_toggle) {
        return "おまかせ機能がONです";
      } else {
        return "おまかせ機能がOFFです";
      }
    },
  },
  methods: {
    async changeFullAuto(new_value) {
      this.loadingToggle = true;
      this.$emit("updateFullAuto", new_value);
      this.loadingToggle = false;
    },
    ...mapMutations({
      SET_USER,
    }),
  },
};
</script>
